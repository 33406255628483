<template>
  <v-dialog v-model="modalDetail" :width="CWidth">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Detail Volunteer</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form" v-if="item">
        <div class="pa-5">
          <v-col cols="12" md="12">
            <v-row class="mb-n8">
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="item.vol_nik"
                  outlined
                  dense
                  label="NIK Volunteer"
                  autocomplete="off"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-n8">
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="item.vol_nama"
                  outlined
                  dense
                  label="Nama Volunteer"
                  autocomplete="off"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-n8">
              <v-col cols="12" md="4">
                <v-select
                  v-model="item.vol_kelamin"
                  outlined
                  dense
                  :items="refjenkel"
                  item-value="id"
                  item-text="nama"
                  label="Jenis Kelamin"
                  autocomplete="off"
                  readonly
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="item.vol_usia"
                  outlined
                  dense
                  label="Usia"
                  autocomplete="off"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="item.vol_tlp"
                  outlined
                  dense
                  label="No Telpon Volunteer"
                  autocomplete="off"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-n8">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="item.vol_rt"
                  outlined
                  dense
                  label="RT"
                  autocomplete="off"
                  readonly
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="item.vol_rw"
                  outlined
                  dense
                  label="RW"
                  autocomplete="off"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="item.kec_id"
                  outlined
                  dense
                  :items="refKec"
                  item-value="kec_id"
                  item-text="kec_nama"
                  label="Kecamatan"
                  autocomplete="off"
                  readonly
                  filled
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="item.kel_id"
                  outlined
                  dense
                  :items="refKel"
                  item-value="kel_id"
                  item-text="kel_nama"
                  label="Kelurahan"
                  autocomplete="off"
                  readonly
                  filled
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="item.tps_id"
                  outlined
                  dense
                  :items="refTps"
                  item-value="tps_id"
                  item-text="tps_nama"
                  label="TPS"
                  autocomplete="off"
                  readonly
                  filled
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";

import modalDetail from "@/store/volunteer/detail";

export default {
  computed: {
    modalDetail: {
      get() {
        return modalDetail.state.modalDetail;
      },
      set(value) {
        modalDetail.commit("toggleModal", value);
      },
    },

    item: {
      get() {
        return modalDetail.state.data;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  watch: {
    async modalDetail() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refKec = await getRef.Kec(this.session.token);
      this.refKel = await getRef.Kel(this.session.token, this.item.kec_id);
      this.refTps = await getRef.Tps(this.session.token, this.item.kel_id);
    },
  },

  data: () => ({
    session: "",
    CWidth: "60%",

    refjenkel: [
      {
        id: "P",
        nama: "Perempuan",
      },
      {
        id: "L",
        nama: "Laki-Laki",
      },
    ],

    refKec: [],
    refKel: [],
    refTps: [],
  }),

  methods: {
    closeModal() {
      this.modalDetail = false;
    },
  },
};
</script>
