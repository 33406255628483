<template>
  <v-dialog v-model="ModalAdd" :max-width="dekstop" persistent>
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="utama"
            fixed
            bottom
            right
            fab
            dark
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
            @click="openModal()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Tambah Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="utama" dense flat>
        <v-toolbar-title>Tambah Data</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="pa-5">
          <v-col cols="12" md="12">
            <v-row class="mb-n8">
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="vol_nama"
                  outlined
                  dense
                  :rules="fRules"
                  label="Nama Volunteer"
                  autocomplete="off"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="vol_nik"
                  outlined
                  dense
                  :rules="fRules"
                  label="NIK Volunteer"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-n8">
              <v-col cols="12" md="4">
                <v-select
                  v-model="vol_kelamin"
                  outlined
                  dense
                  :items="refjenkel"
                  item-value="id"
                  item-text="nama"
                  label="Jenis Kelamin"
                  autocomplete="off"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="vol_usia"
                  outlined
                  dense
                  label="Usia"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="vol_tlp"
                  outlined
                  dense
                  label="No Telpon Volunteer"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-n8">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="vol_rt"
                  outlined
                  dense
                  label="RT"
                  autocomplete="off"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="vol_rw"
                  outlined
                  dense
                  label="RW"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="vol_refdata"
                  outlined
                  dense
                  :rules="fRules"
                  label="Sumber Data"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="4">
                <v-select
                  v-model="kec_id"
                  outlined
                  dense
                  :items="refKec"
                  item-value="kec_id"
                  item-text="kec_nama"
                  :rules="fRules"
                  label="Kecamatan"
                  autocomplete="off"
                  @change="Ckec"
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="kel_id"
                  outlined
                  dense
                  :items="refKel"
                  item-value="kel_id"
                  item-text="kel_nama"
                  :rules="fRules"
                  label="Kelurahan"
                  autocomplete="off"
                  @change="Ckel"
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="tps_id"
                  outlined
                  dense
                  :items="refTps"
                  item-value="tps_id"
                  item-text="tps_nama"
                  :rules="fRules"
                  label="TPS"
                  autocomplete="off"
                ></v-select>
              </v-col>
            </v-row> -->
          </v-col>

          <hr />

          <div class="text-right mt-5">
            <v-btn
              color="primary"
              depressed
              :loading="lbtn"
              :disabled="lbtn"
              @click="simpan()"
            >
              Simpan
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";

import refreshView from "@/store/volunteer/view";

export default {
  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.dekstop = "100%";
    }
  },

  data: () => ({
    session: "",
    dekstop: "60%",
    lbtn: false,
    show: false,
    ModalAdd: false,

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],

    refjenkel: [
      {
        id: "P",
        nama: "Perempuan",
      },
      {
        id: "L",
        nama: "Laki-Laki",
      },
    ],

    refKec: [],
    refKel: [],
    refTps: [],

    vol_nik: "",
    vol_nama: "",
    vol_tlp: "",
    vol_kelamin: "",
    vol_usia: "",
    vol_rt: "",
    vol_rw: "",
    vol_refdata: "",
    // kec_id: "",
    // kel_id: "",
    // tps_id: "",
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    async openModal() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refKec = await getRef.Kec(this.session.token);
      this.ModalAdd = true;
    },

    default() {
      this.vol_nik = "";
      this.vol_nama = "";
      this.vol_tlp = "";
      this.vol_kelamin = "";
      this.vol_usia = "";
      this.vol_rt = "";
      this.vol_rw = "";
      this.vol_refdata = "";
      // this.kec_id = "";
      // this.kel_id = "";
      // this.tps_id = "";
    },

    simpan() {
      this.lbtn = true;

      const data = {
        vol_nik: this.vol_nik,
        vol_nama: this.vol_nama,
        vol_tlp: this.vol_tlp,
        vol_kelamin: this.vol_kelamin,
        vol_usia: this.vol_usia,
        vol_rt: this.vol_rt,
        vol_rw: this.vol_rw,
        vol_refdata: this.vol_refdata,
        kec_id: this.$route.query.kec_id,
        kel_id: this.$route.query.kel_id,
        tps_id: this.$route.query.tps_id,
      };

      const url = process.env.VUE_APP_API + "volunteer";
      this.axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.lbtn = false;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.lbtn = false;
          this.closeModal();
        });
    },

    async Ckec(value) {
      this.refKel = await getRef.Kel(this.session.token, value);
    },

    async Ckel(value) {
      this.refTps = await getRef.Tps(this.session.token, value);
    },

    closeModal() {
      this.default();
      this.ModalAdd = false;
    },
  },
};
</script>
