<template>
  <div>
    <v-col cols="12">
      <v-row>
        <div class="my-auto">
          <h2 class="font-weight-black primary--text">DATA VOLUNTEER</h2>
          <CmodalAdd />
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-breadcrumbs :items="items">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :href="item.href"
                :disabled="item.disabled"
                v-if="item.text"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>
      </v-row>
    </v-col>

    <v-divider></v-divider>

    <div class="my-5">
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div class="my-5 pb-12">
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="volunteer"
          :loading="loading"
        >
          <template v-slot:top>
            <CmodalDetail />
            <CmodalEdit />
            <CmodalHapus />
          </template>
          <template v-slot:[`item.nomor`]="{ item }">
            {{
              volunteer
                .map(function (x) {
                  return x.vol_id;
                })
                .indexOf(item.vol_id) + 1
            }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small class="mr-1" @click="detail(item)">
              mdi-archive
            </v-icon>
            <v-icon small class="mr-1" @click="edit(item)"> mdi-pencil </v-icon>
            <v-icon small @click="hapus(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import CmodalAdd from "@/components/volunteer/modal_add";
import CmodalDetail from "@/components/volunteer/modal_detail";
import CmodalEdit from "@/components/volunteer/modal_edit";
import CmodalHapus from "@/components/volunteer/modal_hapus";

import refreshView from "@/store/volunteer/view";
import modalDetail from "@/store/volunteer/detail";
import modalEdit from "@/store/volunteer/edit";
import modalHapus from "@/store/volunteer/hapus";

export default {
  components: {
    CmodalAdd,
    CmodalEdit,
    CmodalDetail,
    CmodalHapus,
  },

  computed: {
    items() {
      let arr = [
        {
          text: "Volunteer",
          disabled: false,
          href: "/volunteer",
        },
        {
          text: this.data.kec_nama,
          disabled: false,
          href: "/volunteer/kecamatan?id=" + this.$route.query.kec_id,
        },
        {
          text: this.data.kel_nama,
          disabled: false,
          href:
            "/volunteer/kelurahan?kel_id=" +
            this.$route.query.kel_id +
            "&kec_id=" +
            this.$route.query.kec_id,
        },
        {
          text: this.data.tps_nama,
          disabled: false,
          href:
            "/volunteer/tps?tps_id=" +
            this.$route.query.tps_id +
            "&kel_id=" +
            this.$route.query.kel_id +
            "&kec_id=" +
            this.$route.query.kec_id,
        },
      ];

      return arr;
    },

    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },
      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },
      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },
      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    session: "",
    search: "",
    loading: true,

    volunteer: [],

    data: {},

    headers: [
      {
        text: "No",
        value: "nomor",
        width: "50px",
        align: "center",
        sortable: false,
      },
      { text: "NIK Volunteer", align: "start", value: "vol_nik" },
      { text: "Nama Volunteer", align: "start", value: "vol_nama" },
      { text: "No Telpon Volunteer", align: "start", value: "vol_tlp" },
      { text: "Kecamatan", align: "start", value: "kec_nama" },
      { text: "Kelurahan", align: "start", value: "kel_nama" },
      { text: "TPS", align: "start", value: "tps_nama" },
      { text: "Sumber Data", align: "start", value: "vol_refdata" },
      {
        text: "Action",
        align: "center",
        value: "action",
        width: "100px",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
  },

  methods: {
    getData() {
      this.axios
        .get(
          process.env.VUE_APP_API + "tps/detail/" + this.$route.query.tps_id,
          {
            headers: {
              Authorization: "Bearer " + this.session.token,
            },
          }
        )
        .then((res) => {
          this.data = res.data.data;
          this.getVol();
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },

    getVol() {
      let url =
        process.env.VUE_APP_API +
        "volunteer?kec_id=" +
        this.$route.query.kec_id +
        "&kel_id=" +
        this.$route.query.kel_id +
        "&tps_id=" +
        this.$route.query.tps_id;

      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((res) => {
          refreshView.commit("refreshData", false);
          this.volunteer = res.data.data;
          this.loading = false;
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
        });
    },

    detail(value) {
      modalDetail.commit("toggleModal", true);
      modalDetail.commit("viewModal", Object.assign({}, value));
    },

    edit(value) {
      modalEdit.commit("toggleModal", true);
      modalEdit.commit("viewModal", Object.assign({}, value));
    },

    hapus(value) {
      modalHapus.commit("toggleModal", true);
      modalHapus.commit("viewModal", Object.assign({}, value));
    },
  },
};
</script>
