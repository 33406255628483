<template>
  <v-dialog v-model="modalEdit" :max-width="dekstop" persistent>
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Edit TPS</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="pa-5">
          <v-col cols="12" md="12">
            <v-row class="mb-n8">
              <v-col cols="12">
                <v-text-field
                  v-model="item.vol_nik"
                  outlined
                  dense
                  :rules="fRules"
                  label="NIK Volunteer"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="mb-n8">
                <v-text-field
                  v-model="item.vol_nama"
                  outlined
                  dense
                  :rules="fRules"
                  label="Nama Volunteer"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="mb-n8">
                <v-text-field
                  v-model="item.vol_tlp"
                  outlined
                  dense
                  :rules="fRules"
                  label="No Telpon Volunteer"
                  autocomplete="off"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.vol_refdata"
                  outlined
                  dense
                  :rules="fRules"
                  label="Sumber Data"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="4">
                <v-select
                  v-model="item.kec_id"
                  outlined
                  dense
                  :items="refKec"
                  item-value="kec_id"
                  item-text="kec_nama"
                  :rules="fRules"
                  label="Kecamatan"
                  autocomplete="off"
                  @change="Ckec"
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="item.kel_id"
                  outlined
                  dense
                  :items="refKel"
                  item-value="kel_id"
                  item-text="kel_nama"
                  :rules="fRules"
                  label="Kelurahan"
                  autocomplete="off"
                  @change="Ckel"
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="item.tps_id"
                  outlined
                  dense
                  :items="refTps"
                  item-value="tps_id"
                  item-text="tps_nama"
                  :rules="fRules"
                  label="TPS"
                  autocomplete="off"
                ></v-select>
              </v-col>
            </v-row> -->
          </v-col>

          <hr />

          <div class="text-right mt-5">
            <v-btn
              color="primary"
              depressed
              :loading="lbtn"
              :disabled="lbtn"
              @click="simpan()"
            >
              Simpan
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";

import modalEdit from "@/store/volunteer/edit";
import refreshView from "@/store/volunteer/view";

export default {
  computed: {
    modalEdit: {
      get() {
        return modalEdit.state.modalEdit;
      },
      set(value) {
        modalEdit.commit("toggleModal", value);
      },
    },

    item: {
      get() {
        return modalEdit.state.data;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async modalEdit() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refKec = await getRef.Kec(this.session.token);
      this.refKel = await getRef.Kel(this.session.token, this.item.kec_id);
      this.refTps = await getRef.Tps(this.session.token, this.item.kel_id);
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  data: () => ({
    session: "",
    dekstop: "60%",
    lbtn: false,
    show: false,

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],

    refKec: [],
    refKel: [],
    refTps: [],
  }),

  methods: {
    async simpan() {
      this.lbtn = true;

      const data = {
        vol_id: this.item.vol_id,
        vol_nik: this.item.vol_nik,
        vol_nama: this.item.vol_nama,
        vol_tlp: this.item.vol_tlp,
        vol_refdata: this.item.vol_refdata,
        vol_created_by: this.item.vol_created_by,
        kec_id: this.$route.query.kec_id,
        kel_id: this.$route.query.kel_id,
        tps_id: this.$route.query.tps_id,
      };

      const url = process.env.VUE_APP_API + "volunteer";
      this.axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.lbtn = false;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.lbtn = false;
          this.closeModal();
        });
    },

    async Ckec(value) {
      this.refKel = await getRef.Kel(this.session.token, value);
    },

    async Ckel(value) {
      this.refTps = await getRef.Tps(this.session.token, value);
    },

    closeModal() {
      this.modalEdit = false;
    },
  },
};
</script>
